<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="editCategory">
      <b-form
          class="mt-2"
          @submit.prevent="handleSubmit"
          @keydown.enter="handleSubmit"
      >
        <b-row>
          <b-col cols="12">
            <h4 class="mb-2" >{{ $t('Categoría') }}</h4>
          </b-col>
          <b-col sm="6">
            <validation-provider
                #default="{ errors }"
                :name="$t('Nombre')"
                rules="required"
            >
              <b-form-group
                :label="$t('Nombre')"
                label-for="category-name"
              >
                <b-form-input
                  v-model="category.name"
                  name="name"
                  :readonly="role !== 'super_admin' && role !== 'admin_empresa'"
                  :placeholder="$t('Nombre')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="$t('Padre')"
              label-for="category-parent"
            >
              <SelectCategories v-model="parent" :readonly="role !== 'super_admin' && role !== 'admin_empresa'" />
            </b-form-group>
          </b-col>
          <b-col sm="6" v-if="role === 'super_admin' && role === 'admin_empresa'" >
            <validation-provider
                #default="{ errors }"
                :name="$t('Tipo')"
                rules="required"
            >
              <b-form-group
                  :label="$t('Tipo')"
                  label-for="category-type"
              >
                <v-select
                    label="name"
                    :filterable="false"
                    :searchable="false"
                    :options="selectTypes"
                    v-model="type"
                    :placeholder="$t('Tipo')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="12" v-if="role === 'super_admin' && role === 'admin_empresa'" >
            <validation-provider
              #default="{ errors }"
              :name="$t('Descripcion')"
              rules="required"
            >
              <b-form-group
                :label="$t('Descripcion')"
                label-for="category-description"
              >
                <quill-editor
                  v-model="category.description"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col
              cols="12"
          >
            <b-form-group
                :label="$t('FormularioIncidencia')"
                label-for="form-clients-incidencia"
            >
              <v-select
                  label="name"
                  :filterable="false"
                  :searchable="false"
                  :options="selectFormsIncidence"
                  v-model="form_id_incidence"
              />
            </b-form-group>
          </b-col>
          <b-col
              cols="12"
          >
            <b-form-group
                :label="$t('FormularioRevision')"
                label-for="form-clients"
            >
              <v-select
                  label="name"
                  :filterable="false"
                  :searchable="false"
                  :options="selectFormsReview"
                  v-model="form_id"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" class="text-right" >
            <b-button
              type="submit"
              variant="primary"
              class="mt-2 mr-1"
            >
              {{ $t('Enviar') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BCardText, BListGroupItem,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import "leaflet/dist/leaflet.css"
import SelectCategories from "@/components/selectCategories/SelectCategories.vue";
import i18n from '@/libs/i18n'
export default {
  components: {
    SelectCategories,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    BListGroupItem,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      parent: '',
      type: '',
      form_id: '',
      form_id_incidence: '',
      selectFormsReview: [],
      selectFormsIncidence: []
    }
  },
  computed: {
    ...mapGetters({
      category: 'categories/getCategory',
      currentClient: 'clients/getCurrentClient',
      selectForms: 'forms/getItems',
      formSelected: 'forms/getForm',
      selectTypes: 'clients/getSelectTypesAvailables',
      role: 'auth/getRole',
    }),
  },
  methods: {
    ...mapActions({
      assign: 'forms/assign',
      edit: 'categories/edit',
      getSelectForms: 'forms/getList',
      getCategory: 'categories/getCategory',
      getForm: 'forms/getForm',
    }),
    handleSubmit() {
      this.$refs.editCategory.validate().then(success => {
        if (success) {
          const { id } = this.category
          const formData = this.createFormData()
          const formId = this.form_id?.id || '';
          const clientId = (this.role === 'admin_cliente') ? this.currentClient.id : ''

          // Assign values for review_asset
          if(this.form_id) {
            this.assign({
              form_id: formId,
              category_id: id,
              event: 'review',
              client_id: clientId
            });
          }

          // Assign values for incidence_asset
          if(this.form_id_incidence){
            const formIdIncidence = this.form_id_incidence?.id || '';
            this.assign({ form_id: formIdIncidence, category_id: id, event: 'incidence', client_id: clientId });
          }

          if (formData) {
            this.edit({ id, category: formData })
          }
        }
      })
    },
    createFormData() {
      const data = new FormData()
      if (this.parent) {
        data.append('parent_id', this.parent.id)
      } else {
        data.append('parent_id', '')
      }
      data.append('type', this.type.value)
      data.append('name', this.category.name)
      data.append('description', this.category.description)

      return data
    },
    async setData() {
      if (this.category.parent) {
        this.parent = this.category.parent
      }
      if (this.category.type) {
        this.type = {
          name: i18n.t(this.category.type),
          value: this.category.type,
        }
      }
      if (this.category.forms && this.category.forms.review) {
        if(this.category.forms.review[0].form_id){
          await this.getForm(this.category.forms.review[0].form_id)
          this.form_id = this.formSelected
        }
      }
      if (this.category.forms && this.category.forms.incidence) {
        if(this.category.forms.incidence[0].form_id){
          await this.getForm(this.category.forms.incidence[0].form_id)
          this.form_id_incidence = this.formSelected
        }
      }
    },
  },
  async created() {
    await this.getCategory(this.$route.params.id)
    await this.setData()

    await this.getSelectForms({
      page: 1, per_page: 99999, search: '', type: 'review',
    }).then(() => {
      this.selectFormsReview = this.selectForms
    })

    await this.getSelectForms({
      page: 1, per_page: 99999, search: '', type: 'incidence',
    }).then(() => {
      this.selectFormsIncidence = this.selectForms
    })

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.vue2leaflet-map {
  &.leaflet-container {
    height: 450px;
  }
}
</style>
